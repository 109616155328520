import { useCallback, useState, useEffect } from 'react';
import './App.scss';

const VOWEL_REGEX = /[aAeEiIoOuUyYåÅäÄöÖ]/gm;
const END_OF_WORD_REGEX = /(((?<!e)(?=\b\s+\b)|e(?=\b\s+\b))|((?<!e)(?=\s*$)|e(?=\s*$)))/gmi;

const MODE = {
  OOB: 'oob',
  ELIE: 'elie'
};

const transformText = (text, mode) => {
  if (!text) {
    return '';
  }

  switch (mode) {
    case MODE.ELIE:
      return text.replace(END_OF_WORD_REGEX, 'elie');
    case MODE.OOB:
    default:
      return text.replace(VOWEL_REGEX, 'oob');
  }
}

function App() {
  const [mode, setMode] = useState(MODE.OOB);
  const [hasInput, setHasInput] = useState(false);
  const [inputText, setInputText] = useState(undefined);
  const [outputText, setOutputText] = useState(undefined);

  const onInput = useCallback((text) => {
    setHasInput(true);
    setInputText(text)
  }, []);

  useEffect(() => {
    if (inputText) {
      setOutputText(transformText(inputText, mode));
    }
  }, [inputText, mode]);

  return (
    <div className="app">
      <header className="app-header">
        <h1>
          <div className="switch-button">
            <input className="switch-button-checkbox" type="checkbox" onChange={(e) => setMode(e.target.checked ? MODE.ELIE : MODE.OOB)}></input>
            <label className="switch-button-label" for="">
              <span className="switch-button-label-span">
                oob
              </span>
            </label>
          </div>
        </h1>
      </header>
      <main className="app-content">
        <div>
          <textarea
            autoFocus
            className="input"
            value={inputText}
            onChange={(event) => onInput(event.target.value)}
            placeholder={mode === MODE.OOB ? "toobxt hoobroob" : "textelie herelie"}
          />
        </div>
        <div>
          {hasInput && outputText &&
            <p className="output">
              {outputText}
            </p>
          }
        </div>
      </main>
      <footer className="app-footer">
        Created for reasons - Drapan 2021
      </footer>
    </div>
  );
}

export default App;
